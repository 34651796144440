import { Element, Frame } from '@craftjs/core';
import loadable from '@loadable/component';
import { decode } from 'js-base64';
import { useEffect } from 'react';

import { LayoutSingleColumn, MobileBottomNavigation, Page } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import { CustomEditor } from 'containers/EditablePages/CustomEditor';
import { useGetEditablePage } from 'containers/EditablePages/EditablePages.hooks';
import { useIsScrollingDisabled } from 'hooks/selectors/useIsScrollingDisabled';
import { useGeolocation } from 'hooks/useGeolocation';
import { sendGa4Event } from 'util/GA4Events';

const BackupHome = loadable(() => import('./CustomLandingPage.backup'));

const CustomLandingPage = () => {
  const { userGeolocation, isLoading: isLoadingUser } = useGeolocation();

  useEffect(() => {
    sendGa4Event('home_page_visited', {});
  }, []);

  if (isLoadingUser) {
    return null;
  }
  try {
    if (userGeolocation === 'BG') {
      return <EditablePage key="nold-bg" pageId="nold-bg" />;
    } else {
      // TODO: Preload home page in SSR
      return <EditablePage key="home" pageId="home" />;
    }
  } catch (error) {
    console.error('Error fetching landing page', error);
  }

  return <BackupHome />;
};

const EditablePage = ({ pageId }: { pageId: string }) => {
  const isScrollingDisabled = useIsScrollingDisabled();
  const { data: page, isLoading, isError, error } = useGetEditablePage(pageId);

  if (isLoading) {
    return null;
  }
  if (isError) {
    throw new Error(`Failed to fetch page ${pageId}`, { cause: error });
  }

  return (
    <Page title="THE NOLD" scrollingDisabled={isScrollingDisabled}>
      <CustomEditor>
        <div className="grid grid-cols-[1fr_auto] w-screen h-screen">
          <LayoutSingleColumn
            topbar={<TopbarContainer />}
            footer={<MobileBottomNavigation />}
            footerClassName="w-full overflow-x-hidden"
            className="w-full"
            mainColumnClassName="w-full overflow-x-hidden"
          >
            <Frame json={decode(page?.content ?? '')}>
              <Element
                canvas
                is="div"
                className="craftjs-renderer min-h-px w-full overflow-x-hidden"
                id="root"
              ></Element>
            </Frame>
          </LayoutSingleColumn>
        </div>
      </CustomEditor>
    </Page>
  );
};

export default CustomLandingPage;
